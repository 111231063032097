import React, { ClipboardEvent, useRef, useState, useMemo } from 'react';
import { InputMaskChangeParams } from 'primereact/inputmask';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import UnstyledInputMask from '../UnstyledInputMask';
import { focusedInputStyle } from '../Styles';

export interface LogisticsDateOnlyProps {
  value: Date | undefined | null,
  onChange: (newValue: Date | undefined) => any,
  showSetToNowButton?: boolean | undefined,
  isValidated?: boolean | undefined,
  disabled?: boolean | undefined
}

const dateFormat = 'MM/DD/YYYY';

const dateMask = '99/99/9999';

const dateToString = (date: Date | null | undefined, dateFormat: string) => {
  if (!date) return undefined;

  return dayjs.utc(date).format(dateFormat);
};

const HiddenCalendar = styled(Calendar)`
  &, & input {
    all: unset;
    width: 0;
  }
`

const CustomInput = styled.div`
  & {
    display: flex;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0;
    border-bottom: 1px solid #ced4da;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
  }
  &.focused {
    ${focusedInputStyle}
  }
  &.validated {
    border-bottom: 1px solid red;
  }
`

// Use this for working with pure dates. Time part and timezone are completely ignored
function LogisticsDateOnly(props: LogisticsDateOnlyProps) {
  const { isValidated, disabled } = props;

  const textValueFromProps = useMemo(
    () => dateToString(props.value, dateFormat),
    [props.value]);
  const [textValue, setTextValue] = useState(textValueFromProps);
  if ((!textValue && textValueFromProps)
    || (textValue && textValueFromProps && textValue !== textValueFromProps)) {
    // React to external value changes
    // (empty value to non-empty value and non-empty value to different non-empty value).
    setTextValue(textValueFromProps);
  }

  const calendarRef = useRef<Calendar>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const underlineClass = useMemo(() => {
    if (isValidated) return 'validated';
    if (focused) return 'focused';
    return '';
  }, [isValidated, focused]);

  const handleOnCalendarChange = (e: CalendarChangeParams) => {
    const newDate = e.value instanceof Array
      ? e.value[0]
      : e.value;
    if (typeof(newDate) === 'string') {
      throw new Error('Not supported');
    }

    if (newDate === undefined || newDate === null) {
      setTextValue('');
      return;
    }

    const pureDate = toPureDate(newDate);
    const newTextDate = dateToString(pureDate, dateFormat);
    setTextValue(newTextDate);
    props.onChange(pureDate);
  }

  const handleOnInputMaskChange = (e:  InputMaskChangeParams) => {
    let copiedValue;
    if (e.originalEvent.type === 'paste') {
      copiedValue = (e.originalEvent as ClipboardEvent).clipboardData.getData('text');
    }

    const newTextDate = copiedValue || e.value || '';
    if (newTextDate === textValue) return;

    setTextValue(newTextDate);

    const newDate = dayjs(newTextDate, dateFormat, true);
    if (newDate.isValid()) {
      const pureDate = toPureDate(newDate.toDate());
      props.onChange(pureDate);
    } else {
      props.onChange(undefined);
    }
  }

  const setToNowButton = props.showSetToNowButton
    ? <Button
      icon='pi pi-clock'
      className='p-button-lg p-button-rounded p-button-text p-button-plain'
      onClick={() => {
        const now = new Date();
        props.onChange(now);
        setTextValue(dateToString(now, dateFormat));
      }}
      disabled={disabled}
    />
    : <></>;

  return (
    <CustomInput ref={wrapperRef} className={underlineClass}>
      <HiddenCalendar
        ref={calendarRef}
        value={props.value || undefined}
        showTime={false}
        hourFormat='12'
        onChange={handleOnCalendarChange}
      />
      {setToNowButton}
      <UnstyledInputMask
        className='flex-1'
        mask={dateMask}
        value={textValue}
        onChange={handleOnInputMaskChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder={dateFormat}
        disabled={disabled}
      />
      <Button
        icon='pi pi-calendar'
        className='p-button-lg p-button-rounded p-button-text p-button-plain'
        onClick={() => {
          calendarRef?.current?.show();
        }}
        disabled={disabled}
      />
    </CustomInput>
  );
}

/*
  Add timezone difference.
 */
function toPureDate(date: Date | undefined) {
  if (!date) return date;

  const pureDateString = dayjs(date).format('YYYY-MM-DD');
  const pureDate = new Date(pureDateString);
  return pureDate;
}

export default LogisticsDateOnly;